import {
  PersonalInformations,
  SectionTitle,
  StatusBox,
} from '@digital-at-vallourec/steel-design-system-react';
import { Grid, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks';
import { Address, PickupAddress } from '../../../interfaces/address';
import { BookingDetails } from '../../../interfaces/veg';
import { getUser } from '../../../services/api/user-api';
import { getAddress, getPickupAddress } from '../../../services/api/veg-api';
import { subscribe, unsubscribe } from '../../../services/core/event';
import { selectProductQualifications } from '../../../services/store/licenseeContextSlice';
import { selectUser } from '../../../services/store/userSlice';
import { DeliveryMode, MAP_INDEX_DELIVERY } from '../../../utils';
import { ROLES } from '../../../utils/constants/auth0-roles';
import { BOOKING_QUALIFICATION_EVENT } from '../../../utils/constants/custom-event';
import { checkAuthorizationByRole, productToQualification } from '../../../utils/functions';
import { orderInformationsToObject } from '../../../utils/functions/informations-to-personal-information-object';
import { ContainerCard, FormTextField, RentalTerm } from '../../shared';
import { StatusLabelBox } from '../../shared/StatusLabelBox/StatusLabelBox';

export interface OrderPersonalInfos {
  firstname: string;
  lastname: string;
  job: string;
  email: string;
  phoneNumber;
  deliveryAddress: Address | PickupAddress;
}

interface OrderInformationsProps {
  loaderData: BookingDetails;
}

export function OrderInformations({
  loaderData: {
    delivery_address_id,
    pickup_address_id,
    user_id,
    rental_type,
    delivery_mode,
    pickup_contact_name,
    for_qualification,
  },
}: OrderInformationsProps) {
  const { t } = useTranslation('gauge');
  const [forQualificationActive, setForQualificationActive] = useState(for_qualification);
  const [orderPersonalInfos, setOrderPersonalInfos] = useState<OrderPersonalInfos>({
    firstname: null,
    lastname: null,
    job: null,
    email: null,
    phoneNumber: null,
    deliveryAddress: null,
  });
  const products = useAppSelector(selectProductQualifications);
  const user = useAppSelector(selectUser);

  useEffect(() => {
    Promise.all([
      getUser(user_id),
      delivery_mode === DeliveryMode.Delivery
        ? getAddress(delivery_address_id)
        : getPickupAddress(pickup_address_id),
    ]).then((values) => {
      const [{ data: userData }, { data: deliveryAddress }] = values;
      const data: OrderPersonalInfos = {
        firstname: userData?.first_name || '-',
        lastname: userData?.last_name || '-',
        job: userData?.job || '-',
        email: userData?.email || '-',
        phoneNumber: userData?.phone_number || '-',
        deliveryAddress: deliveryAddress,
      };
      setOrderPersonalInfos(data);
    });
  }, []);

  useEffect(() => {
    subscribe(BOOKING_QUALIFICATION_EVENT, triggerBookingQualification);

    return () => {
      unsubscribe(BOOKING_QUALIFICATION_EVENT, triggerBookingQualification);
    };
  }, []);

  const triggerBookingQualification = ({
    detail,
  }: {
    detail: { connection: string; end: string };
  }) => {
    setForQualificationActive(productToQualification(detail.connection, detail.end, products));
  };

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      avatarHeaderNode={
        <SectionTitle
          title={t('manageBookingDetail.orderInformations.title')}
          description={t('manageBookingDetail.orderInformations.description')}
        />
      }
      actionHeaderNode={RentalTerm(rental_type)}
    >
      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
        <Grid item xs={6}>
          <FormTextField
            id="licensee_name_id"
            name="licensee_name"
            label={`${t('details.licensee')}*`}
            disabled={!checkAuthorizationByRole(user?.roles, [ROLES.ROLE_LICENSEE_GAUGE])}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="drawing_id"
            name="product_drawing"
            label={`${t('details.productDrawing')}*`}
            disabled={!checkAuthorizationByRole(user?.roles, [ROLES.ROLE_LICENSEE_GAUGE])}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="purchase_order_id"
            name="purchase_order"
            label={`${t('details.purchaseOrder')}*`}
            disabled={!checkAuthorizationByRole(user?.roles, [ROLES.ROLE_LICENSEE_GAUGE])}
          />
        </Grid>
        <Grid item xs={6}>
          <FormTextField
            id="end_user_id"
            name="end_user"
            label={`${t('details.endUser')}*`}
            disabled={!checkAuthorizationByRole(user?.roles, [ROLES.ROLE_LICENSEE_GAUGE])}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="tw-flex tw-w-full">
            <PersonalInformations informations={orderInformationsToObject(orderPersonalInfos)} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="tw-flex tw-w-full">
            <PersonalInformations
              addressTitle={
                delivery_mode === DeliveryMode.Delivery
                  ? t('personal.deliveryAddress')
                  : t('personal.pickupAddress')
              }
              informations={orderInformationsToObject(orderPersonalInfos)}
              isAddress
              contactName={
                delivery_mode === DeliveryMode.Delivery
                  ? orderPersonalInfos?.deliveryAddress?.contact_name
                  : pickup_contact_name
              }
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="tw-flex tw-w-full">
            <PersonalInformations
              addressTitle={t('manageBookingDetail.orderInformations.invoicingAddress')}
              informations={{
                address: {
                  state: '-',
                  street: '-',
                  town: '-',
                  zipCode: '-',
                },
              }}
              isAddress
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <StatusLabelBox label={t('mode.qualification')} isActive={forQualificationActive} />
            <StatusBox
              activeNodeIndex={MAP_INDEX_DELIVERY[delivery_mode]}
              data={[t('mode.delivery'), t('mode.click')]}
            />
          </Stack>
        </Grid>
      </Grid>
    </ContainerCard>
  );
}
