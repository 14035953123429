import { Currency } from '../constants';

export function computeTotalPrice(
  withBident: boolean,
  totalPrice: number,
  bidentPrice: number
): number {
  return withBident ? totalPrice + bidentPrice : totalPrice - bidentPrice;
}

export function formatPrice(price: number, currency: Currency): string {
  const format = currency === Currency.EUR ? 'fr' : 'en-US';
  return new Intl.NumberFormat(format, {
    style: 'currency',
    currency,
  })
    .format(price)
    .replace(/\u00A0/g, ' ');
}
