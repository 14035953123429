import {
  IncrementalComponent,
  Tooltip,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import InfoIcon from '@mui/icons-material/Info';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DhlIcon from '../../../assets/icons/icon-dhl.svg';
import FedexIcon from '../../../assets/icons/icon-fedex.svg';
import { Currency, DispatchBy, ShippingSource } from '../../../utils';
import { getDhlAccountNumber } from '../../../utils/functions/shipping';
import { FormTextField } from '../../shared';

export interface ShippingInformationsProps {
  isReturn?: boolean;
}

export function ShippingInformations({ isReturn }: ShippingInformationsProps) {
  const { t } = useTranslation('gauge');
  const [nbBoxes, setNbBoxes] = React.useState<number>(1);
  const methods = useFormContext();
  const dhlAccountNumber = isReturn
    ? getDhlAccountNumber(ShippingSource.Return)
    : getDhlAccountNumber(ShippingSource.Dispatch);
  const { setValue, trigger, getValues } = methods;

  const handleIncrease = () => {
    const newValue = nbBoxes + 1;
    setValue('nb_boxes', newValue);
    trigger('nb_boxes');
    setNbBoxes(newValue);
  };

  const handleDecrease = () => {
    const newValue = nbBoxes - 1;
    setValue('nb_boxes', newValue);
    trigger('nb_boxes');
    setNbBoxes(newValue);
  };

  const handleDispatchByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('dispatch_by', e.target.value);
    if (e.target.value === DispatchBy.DHL) {
      setValue('account_number', dhlAccountNumber);
    } else {
      setValue('account_number', '');
    }
    trigger(['dispatch_by', 'account_number']);
  };

  const handleCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue('currency', e.target.value);
    trigger('currency');
  };

  return (
    <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 4 }}>
      <Grid item xs={12}>
        <div className="tw-flex tw-flex-row tw-gap-20">
          <div className="tw-items-center tw-flex tw-flex-col tw-gap-2">
            <Typo variant="tableTitle">{t('dispatchDetail.shippingInformations.nbBoxes')}</Typo>
            <IncrementalComponent
              value={nbBoxes}
              handleDecrease={handleDecrease}
              handleIncrease={handleIncrease}
              min={1}
            />
          </div>

          <FormControl>
            <Typo variant="tableTitle">{t('dispatchDetail.shippingInformations.dispatchBy')}</Typo>
            <RadioGroup
              value={getValues('dispatch_by')}
              className="tw-gap-12"
              row
              onChange={handleDispatchByChange}
              data-testid="shipping-informations-dispatch-radio-group"
            >
              <FormControlLabel
                data-testid="shipping-informations-dispatch-radio-other"
                value={DispatchBy.OTHER}
                control={<Radio />}
                label={t('dispatchDetail.shippingInformations.dispatchBy', { context: 'other' })}
              />
              <FormControlLabel
                data-testid="shipping-informations-dispatch-radio-dhl"
                value={DispatchBy.DHL}
                control={<Radio />}
                label={<img src={DhlIcon} alt="dhl-icon" />}
              />
              <FormControlLabel
                data-testid="shipping-informations-dispatch-radio-fedex"
                value={DispatchBy.FEDEX}
                control={<Radio />}
                label={<img src={FedexIcon} alt="fedex-icon" />}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </Grid>
      {getValues('dispatch_by') === DispatchBy.DHL ? (
        <Grid item xs={4}>
          <FormTextField
            name="account_number"
            label="Account number *"
            inputDataTestId="shipping-informations-account-number-textfield"
            onBlur={() => trigger('account_number')}
            InputProps={
              isReturn
                ? {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          arrow
                          placement="top-start"
                          title={t('makeReturn.returnInformations.dhlInfo')}
                          variant="light"
                        >
                          <InfoIcon className="tw-ml-2" fontSize="small" />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }
                : null
            }
          />
        </Grid>
      ) : null}
      <Grid item xs={4}>
        <FormTextField
          name="air_way_bill"
          label="Air way bill *"
          inputDataTestId="shipping-informations-air-way-bill-textfield"
          onBlur={() => trigger('air_way_bill')}
        />
      </Grid>
      <Grid item xs={4}>
        <FormTextField
          name="incoterms"
          label="Incoterms *"
          inputDataTestId="shipping-informations-incoterms-textfield"
          onBlur={() => trigger('incoterms')}
        />
      </Grid>
      <Grid item xs={12}>
        <div className="tw-flex tw-gap-2">
          <Typo className="tw-flex tw-items-center" variant="tableTitle">
            {t('dispatchDetail.shippingInformations.currency')}
          </Typo>
          <RadioGroup
            value={getValues('currency')}
            className="tw-gap-4"
            row
            onChange={handleCurrencyChange}
            data-testid="shipping-informations-currency-radio-group"
          >
            <FormControlLabel
              data-testid="shipping-informations-dispatch-radio-euro"
              value={Currency.EUR}
              control={<Radio />}
              label={
                <Typo variant="sectionTitle">{t('dispatchDetail.shippingInformations.euro')}</Typo>
              }
            />
            <FormControlLabel
              data-testid="shipping-informations-dispatch-radio-dollar"
              value={Currency.USD}
              control={<Radio />}
              label={
                <Typo variant="sectionTitle">
                  {t('dispatchDetail.shippingInformations.dollar')}
                </Typo>
              }
            />
          </RadioGroup>
        </div>
      </Grid>
    </Grid>
  );
}
