import { GridSortModel } from '@mui/x-data-grid';
import React from 'react';

import { ManageBookingGrid } from '../interfaces/veg';
import { getGaugesBookingByStatus } from '../services/api/veg-api';
import {
  BOOKING_STATUS_GRID_MAPPING,
  DEFAULT_PAGE_SIZE,
  DeliveryMode,
  StatusFilters,
} from '../utils';
import { formatDate } from '../utils/functions';
import { isNotNullOrUndefined } from '../utils/functions/object';

export function useFetchGaugesBookingByStatus(
  statusFilters: StatusFilters,
  sort: GridSortModel,
  licensee_number: number,
  page?: number,
  deliveryMode?: DeliveryMode,
  depotId?: number
) {
  const [datagrid, setDatagrid] = React.useState<ManageBookingGrid[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      getGaugesBookingByStatus(
        {
          licensee_number: licensee_number,
          delivery_mode: deliveryMode,
          skip: isNotNullOrUndefined(page) ? DEFAULT_PAGE_SIZE * page : null,
          limit: isNotNullOrUndefined(page) ? DEFAULT_PAGE_SIZE : null,
          sort,
          depot_id: depotId,
        },
        statusFilters
      ).then(({ data }) => {
        const dataGrid = data.map((row, idx) => ({
          id: idx,
          ...row,
          creation_date: formatDate(row.creation_date, false),
          modification_date: row.modification_date
            ? formatDate(row.modification_date, false)
            : null,
          start_date: formatDate(row.start_date, false),
          end_date: formatDate(row.end_date, false),
          grid_status: BOOKING_STATUS_GRID_MAPPING[row.status],
        }));
        setDatagrid(dataGrid);
      });
    };

    fetchData();
  }, [statusFilters.current_status, page, sort]);

  return { datagrid };
}
