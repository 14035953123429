import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  TextFieldVariants,
  TypographyVariant,
} from '@mui/material';
import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { $grey10, $primaryWhite } from '../../../styles/colors';
import { TextFieldVariant } from '../../../utils';
import { getError } from './utils';

// 👇 Type of Props the FormAutocomplete will receive
type FormAutocompleteProps = {
  name: string;
  id: string;
  label?: string;
  variant?: TextFieldVariants;
  options: any[];
  requiredMessage?: string;
  patternValue?: RegExp;
  patternMessage?: string;
  inputDataTestId?: string;
  withFixedLabel?: boolean;
  type?: string;
  labelVariant?: TypographyVariant;
  labelValue?: string;
} & Omit<AutocompleteProps<any, any, any, any, any>, 'renderInput'>;

const StyleFixedLabel = styled(Typo)`
  line-height: 1rem;
`;

const StyledTextField = styled(TextField)`
  background: ${$primaryWhite};
  box-shadow: 0 3px 2px -2px ${$grey10};
  display: flex;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  margin-top: 3px;

  .MuiInput-root {
    font-style: italic;
    font-size: 14px;
    padding: 8px;
    display: flex;
    &:before {
      border-bottom: none;
    }
    &:after {
      border-bottom: none;
    }
    &:focus {
      border-bottom: none;
    }
    &:hover {
      &:not(.Mui-disabled, .Mui-error) {
        &:before {
          border-bottom: none;
        }
      }
    }
  }
`;

export const FormAutocomplete: FC<FormAutocompleteProps> = ({
  name,
  id,
  label,
  variant,
  options,
  requiredMessage,
  patternValue,
  patternMessage,
  inputDataTestId,
  type,
  withFixedLabel,
  labelValue,
  labelVariant,
  ...otherProps
}) => {
  // 👇 Utilizing useFormContext to have access to the form Context
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = getError(errors, name);

  const Body = withFixedLabel ? StyledTextField : TextField;

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: requiredMessage,
        pattern: { value: patternValue, message: patternMessage },
      }}
      render={({ field: { value } }) => (
        <Autocomplete
          options={options}
          value={value}
          id={id}
          data-testid={`${id}-form-autocomplete`}
          renderInput={(params) => (
            <>
              {withFixedLabel ? (
                <StyleFixedLabel
                  component="label"
                  fontWeight={400}
                  data-testid="form-text-field-label"
                  variant={labelVariant || 'overline'}
                >
                  {labelValue}
                </StyleFixedLabel>
              ) : null}
              <Body
                {...params}
                label={label}
                variant={variant || TextFieldVariant.Standard}
                error={!!error}
                helperText={error ? error.message : ''}
                type={type}
                size={withFixedLabel ? 'small' : otherProps.size}
              />
            </>
          )}
          {...otherProps}
        />
      )}
    />
  );
};
