import {
  SectionTitle,
  SimpleButton,
  Tooltip,
} from '@digital-at-vallourec/steel-design-system-react';
import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  addDefaultSides,
  confirmChange,
  createNewSide,
  handleAddSide,
  handleCopySide,
  handleRemoveSide,
  handleSwitchChange,
  shouldConfirmChange,
} from '../../pages/royalties/utils';
import { JobType, TypeOfPart } from '../../utils/constants/royalties';
import { ContainerCard } from '../shared';
import { Side } from './Side';

export function JobDetails() {
  const { t } = useTranslation('royalties', { keyPrefix: 'createJob.jobDetails' });
  const methods = useFormContext();
  const { control, getValues, watch, setValue, trigger } = methods;
  const { append, remove, replace, update } = useFieldArray({ name: 'sides', control });
  const {
    fields,
    append: appendDisplayed,
    remove: removeDisplayed,
    replace: replaceDisplayed,
    update: updateDisplayed,
  } = useFieldArray({ name: 'displayed_sides', control });

  const jobType = watch('job_type');
  const typeOfPart = watch('type_of_part');

  // Track previous values of jobType and typeOfPart
  const [previousJobType, setPreviousJobType] = React.useState<string>('');
  const [previousTypeOfPart, setPreviousTypeOfPart] = React.useState<string>('');
  const [isRestoring, setIsRestoring] = React.useState<boolean>(false); // State to manage restoration

  React.useEffect(() => {
    // Prevent popup from triggering during restoration
    if (isRestoring) {
      setIsRestoring(false);
      return;
    }

    if (jobType === JobType.REPAIR && typeOfPart?.length) {
      // For REPAIR, one side by default, sides can be added manually
      if (fields.length === 0) {
        append(createNewSide());
        appendDisplayed(createNewSide());
      }
    } else if (
      shouldConfirmChange({
        jobType,
        typeOfPart,
        previousJobType,
        previousTypeOfPart,
        fields,
      })
    ) {
      confirmChange({
        jobType,
        typeOfPart,
        replace,
        replaceDisplayed,
        setPreviousJobType,
        setPreviousTypeOfPart,
        setIsRestoring,
        setValue,
        previousJobType,
        previousTypeOfPart,
      });
    } else {
      // Apply default sides based on the new jobType and typeOfPart only
      // if previous typeOfPart has been changed, or it is first select
      if (!previousTypeOfPart.length || previousTypeOfPart !== typeOfPart) {
        addDefaultSides(jobType, typeOfPart, replace);
        addDefaultSides(jobType, typeOfPart, replaceDisplayed);
      }
      if (typeOfPart.length && jobType.length) {
        setPreviousJobType(jobType);
        setPreviousTypeOfPart(typeOfPart);
      }
    }
  }, [jobType, typeOfPart]);

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      containerCardClass="bg-color-white"
      dataTestId="job-details-container"
      avatarHeaderNode={<SectionTitle title={t('title')} description={t('description')} />}
      actionHeaderNode={
        <div className="tw-flex tw-gap-2">
          <Tooltip arrow placement="top-end" title={t('restoreTooltip')} variant="light">
            <div>
              <SimpleButton
                variant="secondary-dark"
                size="small"
                onClick={() => {
                  addDefaultSides(jobType, typeOfPart, replace);
                  addDefaultSides(jobType, typeOfPart, replaceDisplayed);
                }}
                disabled={!jobType.length || !typeOfPart.length}
                data-testid="job-details-restore-btn"
              >
                {t('restore')}
              </SimpleButton>
            </div>
          </Tooltip>
          <SimpleButton
            variant="text"
            size="small"
            startIcon={<Add />}
            onClick={() => handleAddSide(append, appendDisplayed)}
            // Not possible to add a side if job type !== REPAIR and type of part !== Other
            // because number of sides is determined by job type and type of part
            disabled={jobType !== JobType.REPAIR && typeOfPart !== TypeOfPart.OTHER}
            data-testid="job-details-add-btn"
          >
            {t('addBtn')}
          </SimpleButton>
        </div>
      }
    >
      <Grid container columnSpacing={{ xs: 2, sm: 3, md: 4 }} rowGap={1}>
        {fields?.map((field, idx) => {
          return (
            <Side
              key={field.id}
              sideNb={idx}
              onRemove={(sideNb: number) => handleRemoveSide(sideNb, remove, removeDisplayed)}
              handleSwitchChange={(sideNb: number, name: string, checked: boolean) =>
                handleSwitchChange(sideNb, name, checked, setValue, trigger)
              }
              handleCopy={(sideNb: number) =>
                handleCopySide(
                  sideNb,
                  getValues,
                  append,
                  appendDisplayed,
                  update,
                  updateDisplayed,
                  jobType,
                  typeOfPart
                )
              }
            />
          );
        })}
      </Grid>
    </ContainerCard>
  );
}
