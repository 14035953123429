import { GridStatus } from '../../components/data-grid/utils';
import { $darkGreen, $darkRed, $orange } from '../../styles/colors';

export const INTERNAL_LICENSEE_TYPE = 'INTERNAL';
export const LICENSEE_TYPE = 'LICENSEE';

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export const USER_STATUS_COLORS = {
  [UserStatus.ACTIVE]: $darkGreen,
  [UserStatus.INACTIVE]: $orange,
  [UserStatus.SUSPENDED]: $darkRed,
};

export const USER_STATUS_GRID_MAPPING = {
  [UserStatus.ACTIVE]: GridStatus.OK,
  [UserStatus.INACTIVE]: GridStatus.EXPIRE,
  [UserStatus.SUSPENDED]: GridStatus.KO,
};
