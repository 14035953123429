import { FieldOperator } from '../components/documentation/ApplicabilityFilter/utils';
import { Pagination, Sort } from './common';

export enum PublicationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum DocumentFileUnit {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL',
  BOTH = 'BOTH',
}

export interface FileWithUnit {
  file: File;
  unit: DocumentFileUnit;
}

export interface ApplicabilityValueElement {
  applicability_id?: number;
  field: string;
  operator: FieldOperator;
  value: any;
}

export interface ApplicabilityProductDistinct {
  connection: string[];
  od_inch: number[];
  weight: number[];
  thickness: number[];
  end: string[];
  application: string[];
  option: string[];
  special_clearance: string[];
  special_bevel: string[];
  design_option: string[];
  customer: string[];
  connection_type: string[];
}

export interface ApplicabilityGradeDistinct {
  grade_name: string[];
  grade_family: string[];
  yield_strength: number[];
}

export interface ApplicabilityProductGradeDistinct
  extends ApplicabilityGradeDistinct,
    ApplicabilityProductDistinct {}

export interface FileWithUnitPost {
  file: FormData;
  unit: DocumentFileUnit;
}

export interface SaveNewPublication {
  documentation_type: string;
  reference: string;
  designation: string;
  revision: string;
  created_by?: string;
  applicabilities: ApplicabilityValueElement[][];
  specific_licensees: number[];
  notifications_company_types: string[];
  notifications_specific_admins: number[];
  files: FileWithUnit[];
  document_status: PublicationStatus;
}

export interface RevisePublicationData extends SaveNewPublication {
  previous_document_uuid: string;
}

export interface FilterDistinctDocumentParams {
  documentation_type?: string[];
  reference?: string;
  designation?: string;
  revision?: string;
  unit?: DocumentFileUnit;
  document_status?: PublicationStatus;
  connection?: string[];
  od_inch?: number[];
  weight?: number[];
  connection_type?: string[];
  end?: string[];
  grade_family?: string[];
  yield_strength?: number[];
  grade_name?: string[];
  special_clearance?: string[];
  special_bevel?: string[];
  design_option?: string[];
}

export interface ManageDocumentParams extends Sort {
  data_filter: FilterDistinctDocumentParams;
  pagination: Pagination;
}

// DocumentResult interface in TypeScript
export interface ManageDocumentGrid {
  uuid: string;
  reference: string;
  designation: string;
  revision: string;
  unit: string;
  document_status: PublicationStatus;
  file_name: string;
  publication_date: string;
  modification_date: string;
  documentation_type: string;
  file_uuid: string;
}

export interface DocumentResult {
  uuid: string;
  documentation_type: string;
  reference: string;
  designation: string;
  revision: string;
  creation_date: string;
  modification_date?: string;
  created_by: string;
  modified_by?: string;
  document_status: string;
  specific_licensees: number[];
  specific_admins: number[];
  company_types: string[];
  applicabilities: ApplicabilityValueElement[];
  files: FileWithUnitUpdate[];
}

// ManageDocument interface in TypeScript
export interface ManageDocumentResult {
  documents: ManageDocumentGrid[];
  count: number;
}

export interface DownloadFileParams {
  file_uuid: string;
  licensee_number: number;
}

export interface FileWithUnitUpdate extends FileWithUnit {
  file_uuid?: string;
}

export interface FileRemoved {
  name: string;
  file_uuid: string;
  storage_path: string;
}

export interface UpdatePublicationData extends SaveNewPublication {
  document_uuid: string;
  files: FileWithUnitUpdate[];
  removed_files: FileRemoved[];
  modified_by: string;
}
