import { CircleStatus } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Stack } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContainerOutlet } from '../../components/layout/ContainerOutlet/ContainerOutlet';
import {
  FormSchemaType,
  newJobDefaultValues,
  newJobSchema,
} from '../../components/royalties/CreateJobForm';
import { JobDetails } from '../../components/royalties/JobDetails';
import { JobInformations } from '../../components/royalties/JobInformations';
import { CtaLoadingButton, VamPageTitle } from '../../components/shared';
import { $lightBlue } from '../../styles/colors';
import { JOB_STATUS_COLORS, JobStatus } from '../../utils/constants/royalties';
import { getJobTitle } from '../../utils/functions';

export default function CreateJob() {
  const { t } = useTranslation('royalties', { keyPrefix: 'createJob' });
  const methods = useForm<FormSchemaType>({
    defaultValues: newJobDefaultValues,
    resolver: zodResolver(newJobSchema),
  });
  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" spacing={2}>
            <VamPageTitle
              subTitle={t('subtitle')}
              title={getJobTitle()}
              breadcrumbRoutes={[
                {
                  breadcrumb: 'Create a Job',
                  path: '#',
                },
              ]}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <CircleStatus label={t('status.OPEN')} color={JOB_STATUS_COLORS[JobStatus.OPEN]} />
            <CtaLoadingButton
              buttonText={t('submitBtn')}
              type="submit"
              variant="cta"
              data-testid="submit-create-job-btn"
            />
          </Stack>
        </Stack>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          style={{
            backgroundColor: $lightBlue,
          }}
        >
          <Grid item xs={12}>
            <JobInformations />
          </Grid>
          <Grid item xs={6}>
            <JobDetails />
          </Grid>
          <Grid item xs={6}>
            {/* <BillingDetails/> */}
          </Grid>
        </Grid>
      </FormProvider>
    </ContainerOutlet>
  );
}
