import { SectionTitle } from '@digital-at-vallourec/steel-design-system-react';
import { Grid } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LicenseeByType } from '../../interfaces/context';
import { getLicenseeByType } from '../../services/api/context-api';
import { numberRegex } from '../../utils';
import { isoCountriesLabelValue } from '../../utils/constants/countries/countries_code_alpha_2';
import { INTERNAL_LICENSEE_TYPE } from '../../utils/constants/user';
import {
  ContainerCard,
  FormPhoneNumber,
  FormSelect,
  FormSwitchField,
  FormTextField,
} from '../shared';

interface GaugeDepotInformationsProps {
  isUpdate?: boolean;
}

export function GaugeDepotInformations({ isUpdate }: GaugeDepotInformationsProps) {
  const { t } = useTranslation('gauge_depot', { keyPrefix: 'createDepot.depotInformation' });
  const [internalLicensees, setInternalLicensees] = React.useState<LicenseeByType[]>([]);
  const methods = useFormContext();
  const { setValue, trigger, getValues } = methods;

  const handleChangeIsActive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue('is_active', event.target.checked);
    trigger('is_active');
  };

  React.useEffect(() => {
    const fetchData = async () => {
      getLicenseeByType([INTERNAL_LICENSEE_TYPE])
        .then((response) => {
          setInternalLicensees(response.data);
        })
        .catch((error) => {
          /* istanbul ignore next */
          console.error('🚀 ~ file: GaugeDepotCreation.tsx ~ fetchData ~ error', error);
        });
    };
    fetchData();
  }, [isUpdate]);

  return (
    <ContainerCard
      width="100%"
      maxWidth="100%"
      height="100%"
      avatarHeaderNode={<SectionTitle title={t('title')} description={t('description')} />}
    >
      <Grid container spacing={4} lineHeight={2}>
        <Grid item xs={3}>
          <FormTextField
            id="site"
            name="site"
            label={t('form.site')}
            inputDataTestId="depot-site-textfield"
            disabled={isUpdate}
          />
        </Grid>
        <Grid item xs={3}>
          <FormTextField
            id="street"
            name="street"
            label={t('form.street')}
            inputDataTestId="street-depot-textfield"
          />
        </Grid>
        <Grid item xs={3}>
          <FormTextField
            id="town"
            name="town"
            label={t('form.town')}
            inputDataTestId="town-depot-textfield"
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            id="country_code"
            name="country_code"
            variant="standard"
            label={t('form.country')}
            options={isoCountriesLabelValue}
            removeEmptyValue={true}
            data-testid="country-depot-select"
          />
        </Grid>
        <Grid item xs={3}>
          <FormTextField
            id="email"
            name="email"
            label={t('form.email')}
            inputDataTestId="email-depot-textfield"
          />
        </Grid>
        <Grid item xs={3}>
          <FormTextField
            id="zip_code"
            name="zip_code"
            label={t('form.zipCode')}
            inputDataTestId="zip-code-depot-textfield"
            patternValue={numberRegex}
          />
        </Grid>
        <Grid item xs={3}>
          <FormPhoneNumber
            name="phone_number"
            label={t('form.phoneNumber')}
            inputDataTestId="zip-code-depot-textfield"
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            id="licensee_number"
            name="licensee_number"
            variant="standard"
            label={t('form.licenseeNumber')}
            options={internalLicensees.map((licensee) => ({
              label: `${licensee.licensee_number} - ${licensee.licensee_name}`,
              value: `${licensee.licensee_number}`,
              disabled: licensee.is_selected,
            }))}
            removeEmptyValue={true}
            data-testid="licensee-number-depot-select"
          />
        </Grid>
        <Grid item xs={3}>
          <FormSwitchField
            name="is_active"
            label={t('form.isActive')}
            onChange={handleChangeIsActive}
            checked={getValues('is_active')}
            data-testid="is-active-depot-switch"
          />
        </Grid>
      </Grid>
    </ContainerCard>
  );
}
