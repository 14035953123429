import { CircleStatus, SimpleButton } from '@digital-at-vallourec/steel-design-system-react';
import { zodResolver } from '@hookform/resolvers/zod';
import { DateRange } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';
import { TypeOf, boolean, date, number, object, string } from 'zod';

import { useAppSelector } from '../../../hooks';
import { useSelectOrFetchLicenseeQualifications } from '../../../hooks/useSelectOrFetchLicenseeQualifications';
import { useSelectOrFetchPuAddress } from '../../../hooks/useSelectOrFetchPuAddress';
import { AxiosBookingDetails, UpdateBookingParams } from '../../../interfaces/veg';
import { updateBooking, updateBookingStatus } from '../../../services/api/veg-api';
import { selectProductQualifications } from '../../../services/store/licenseeContextSlice';
import { selectIsExternallUser } from '../../../services/store/userSlice';
import { BOOKING_STATUS_COLORS, BookingStatus, RentalType } from '../../../utils';
import { REQUIRED_DATE_FIELD, REQUIRED_FIELD } from '../../../utils/constants/validators-message';
import {
  getDateFromString,
  getLicenseeName,
  getProductsInJson,
  productToQualification,
} from '../../../utils/functions';
import { redirectTo } from '../../../utils/functions/helpers';
import { ContainerOutlet } from '../../layout/ContainerOutlet/ContainerOutlet';
import { MenuOptionsProps, VamPageTitle } from '../../shared';
import { VamMoreOptions } from '../../shared/VamMoreOptions/VamMoreOptions';
import { DialogChangeStatus } from '../reservation-details-actions/DialogChangeStatus';
import { DialogExtendReservation } from '../reservation-details-actions/DialogExtendReservation';
import { applyRulesToTargetStatus, canShowBidentBox } from '../utils';
import GaugeDetails from './GaugeDetails';
import { OrderInformations } from './OrderInformations';
import { Shipment } from './Shipment';

const formSchema = object({
  booking_id: number(),
  start_date: date({
    required_error: REQUIRED_DATE_FIELD,
  }),
  end_date: date({
    required_error: REQUIRED_DATE_FIELD,
  }),
  creation_date: date(),
  business_number: string().min(1, { message: REQUIRED_FIELD }),
  duration: number(),
  connection: string().min(1, { message: REQUIRED_FIELD }),
  od_inch: number(),
  weight_thickness: string().min(1, { message: REQUIRED_FIELD }),
  end: string().min(1, { message: REQUIRED_FIELD }),
  application: string().min(1, { message: REQUIRED_FIELD }),
  option: string().min(1, { message: REQUIRED_FIELD }),
  comments: string().optional(),
  licensee_name: string(),
  product_drawing: string().optional(),
  end_user: string(),
  purchase_order: string(),
  delivery_mode: string().min(1, { message: REQUIRED_FIELD }),
  delivery_address_id: number().optional().nullable(),
  invoicing_address_id: number().optional().nullable(),
  pickup_address_id: number().optional().nullable(),
  pickup_contact_name: string().optional().nullable(),
  bident: boolean(),
  status: string().optional(),
  set_id: number().optional().nullable(),
  user_id: number().optional(),
  licensee_number: number().optional(),
  gauge_location_id: number().optional(),
});

const defaultValues: FormSchemaType = {
  start_date: null,
  end_date: null,
  creation_date: null,
  connection: '',
  od_inch: '' as any,
  weight_thickness: '',
  end: '',
  application: '',
  option: '',
  comments: '',
  licensee_name: '',
  product_drawing: '',
  end_user: '',
  purchase_order: '',
  delivery_mode: '',
  delivery_address_id: null,
  pickup_address_id: null,
  pickup_contact_name: '',
  bident: false,
  business_number: '',
};
// infer schema to generate typescript type
type FormSchemaType = TypeOf<typeof formSchema>;

export function BookingDetail() {
  const { t } = useTranslation('gauge');
  const { t: translateCommon } = useTranslation('common');
  const { t: changeDialogTranslation } = useTranslation('gauge', {
    keyPrefix: 'manageBookingDetail.changeStatusDialog',
  });

  const { enqueueSnackbar } = useSnackbar();
  const [businessNumber, setBusinessNumber] = React.useState<string>('');
  const [totalPrice, setTotalPrice] = React.useState<number>(null);
  const [bidentPrice, setBidentPrice] = React.useState<number>(null);
  const [showBident, setShowBident] = React.useState(false);
  const [defaultBidentChecked, setDefaultBidentChecked] = React.useState(false);
  const [openChangeStatus, setOpenChangeStatus] = React.useState(false);
  const [actualStatus, setActualStatus] = React.useState('');
  const [isSetRelated, setIsSetRelated] = React.useState<boolean>(false);
  const [openExtendBookingDialog, setOpenExtendBookingDialog] = React.useState<boolean>(false);
  const [gaugeLocation, setGaugeLocation] = React.useState<string>(undefined);
  const products = useAppSelector(selectProductQualifications);
  const isExternalUser = useAppSelector(selectIsExternallUser);
  const { puAddressesLabelValue } = useSelectOrFetchPuAddress();
  useSelectOrFetchLicenseeQualifications();

  const location = useLocation();
  const navigate = useNavigate();
  // Determine from which page we come from
  const fromPathname = location?.state?.from?.pathname || '';
  const lastSegment = fromPathname.substring(fromPathname.lastIndexOf('/') + 1);

  let breadcrumbRoutes: any[];
  switch (lastSegment) {
    case 'my-gauges':
      breadcrumbRoutes = [
        {
          breadcrumb: 'My Gauges',
          path: fromPathname,
        },
      ];
      break;

    case 'reservation-management':
      breadcrumbRoutes = [
        {
          breadcrumb: 'Reservation Management',
          path: fromPathname,
        },
      ];
      break;

    case 'rental-data':
      breadcrumbRoutes = [
        {
          breadcrumb: 'Rental Data',
          path: fromPathname,
        },
      ];
      break;

    case 'current-rentals':
      breadcrumbRoutes = [
        {
          breadcrumb: 'Current Rentals',
          path: fromPathname,
        },
      ];
      break;
  }

  breadcrumbRoutes?.push({
    breadcrumb: t('manageBookingDetail.title') + (businessNumber && ` - #${businessNumber}`),
    path: '#',
  });

  const methods = useForm<FormSchemaType>({
    defaultValues: defaultValues,
    resolver: zodResolver(formSchema),
  });
  const { setValue, reset, getValues, handleSubmit } = methods;

  const optionsMenu: MenuOptionsProps[] = [
    {
      label: t('manageBookingDetail.actionsMenu.editRental'),
      disabled: true,
    },
    {
      label: t('manageBookingDetail.actionsMenu.switchTerm'),
      disabled: true,
    },
    {
      label: t('manageBookingDetail.actionsMenu.changeStatus'),
      handleItemClick: () => {
        /* istanbul ignore next */ setOpenChangeStatus(true);
      },
    },
    {
      label: t('manageBookingDetail.actionsMenu.seeAllRentals'),
      disabled: true,
    },
    {
      label: t('manageBookingDetail.actionsMenu.prepareInvoicing'),
      disabled: true,
    },
    {
      label: t('manageBookingDetail.actionsMenu.print'),
      disabled: true,
    },
    {
      label: t('manageBookingDetail.actionsMenu.logs'),
      disabled: true,
    },
    {
      label: t('manageBookingDetail.actionsMenu.cancelReservation'),
      disabled: true,
    },
  ];

  const { data } = useLoaderData() as AxiosBookingDetails;

  useEffect(() => {
    setBusinessNumber(data.business_number);
    setTotalPrice(data.total_price);
    setBidentPrice(data.bident_price);
    setShowBident(canShowBidentBox(data.connection));
    setValue('bident', data.bident);
    setDefaultBidentChecked(data.bident);
    updateActualStatus(data.status);
    // apply data in FormSchema
    reset({
      ...data,
      licensee_name: getLicenseeName(data.licensee_number, data.licensee_name),
      start_date: getDateFromString(data.start_date),
      end_date: getDateFromString(data.end_date),
      creation_date: getDateFromString(data.creation_date),
    } as any);
  }, []);

  useEffect(() => {
    setGaugeLocation(
      puAddressesLabelValue.find((elem) => elem.value === data?.gauge_location_id)?.label
    );
  }, [puAddressesLabelValue, data]);

  const updateActualStatus = (actStatus: string) => {
    setActualStatus(actStatus);
    setValue('status', actStatus);
  };

  /* istanbul ignore next */
  const handleChangePrice = (totalPrice: number, bidentPrice: number) => {
    setTotalPrice(totalPrice);
    setBidentPrice(bidentPrice);
  };

  const handleUpdateBooking: SubmitHandler<FormSchemaType> = async (data) => {
    const values = getValues();
    const for_qualification = productToQualification(data.connection, data.end, products);

    const updateBookingData: UpdateBookingParams = {
      set_id: data.set_id,
      old_set_id: data.set_id,
      modification_date: new Date(),
      user_id: values.user_id,
      licensee_number: values.licensee_number,
      start_date: data.start_date,
      end_date: data.end_date,
      comments: data.comments,
      products: getProductsInJson(data),
      bident: data.bident,
      bident_price: bidentPrice,
      total_price: totalPrice,
      for_qualification,
      status: actualStatus,
    };
    updateBooking(values.booking_id, updateBookingData)
      .then(() => {
        enqueueSnackbar(translateCommon('notification.success'), {
          preventDuplicate: true,
          variant: 'success',
        });
        redirectTo(fromPathname, navigate);
      })
      .catch(function (error) {
        enqueueSnackbar(translateCommon('notification.error'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <FormProvider {...methods}>
        <DialogExtendReservation
          id={data.booking_id}
          openExtendDialog={openExtendBookingDialog}
          setOpenExtendDialog={setOpenExtendBookingDialog}
          endDate={data.end_date}
          updateActualStatus={updateActualStatus}
        />

        <DialogChangeStatus
          id={data.booking_id}
          licensee_number={data.licensee_number}
          currentStatus={actualStatus as BookingStatus}
          initialTargetStatusList={applyRulesToTargetStatus(
            actualStatus,
            data.rental_type as RentalType,
            isSetRelated
          )}
          replaced_booking_id={data.replaced_booking_id}
          openChangeStatusDialog={openChangeStatus}
          setChangeStatusDialog={setOpenChangeStatus}
          translation={changeDialogTranslation}
          submitUpdateStatus={updateBookingStatus}
          updateActualStatus={updateActualStatus}
        />
        <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
          <Stack direction="row" justifyContent="space-between">
            <VamPageTitle
              subTitle={t('tag')}
              title={t('manageBookingDetail.title')}
              detailsLabelBox={[
                {
                  label: businessNumber && `#${businessNumber}`,
                },
                ...(data.replaced_booking_id ? [{ label: 'REPLACEMENT' }] : []),
              ]}
              breadcrumbRoutes={breadcrumbRoutes}
            />
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <SimpleButton
              className={actualStatus !== BookingStatus.ToBeExtended ? 'hidden' : ''}
              variant="cta-sky-blue"
              startIcon={<DateRange />}
              onClick={() => setOpenExtendBookingDialog(true)}
            >
              {t('manageBookingDetail.extendSixMonths')}
            </SimpleButton>
            <CircleStatus
              label={changeDialogTranslation(`status.${actualStatus}`)}
              color={BOOKING_STATUS_COLORS[actualStatus]}
            />
            <VamMoreOptions options={optionsMenu} data-testid="vam-menu-detail-actions" />
            <SimpleButton
              type="submit"
              variant="cta"
              data-testid="update-reservation-details-btn"
              onClick={handleSubmit(handleUpdateBooking)}
            >
              {t('manageBookingDetail.validate')}
            </SimpleButton>
          </Stack>
        </Stack>
        <Grid
          container
          rowSpacing={4}
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          style={{
            backgroundColor: '#f8f9fa',
          }}
        >
          <Grid item xs={6}>
            <GaugeDetails
              handleChangePrice={handleChangePrice}
              bidentPrice={bidentPrice}
              totalPrice={totalPrice}
              showBident={showBident}
              setShowBident={setShowBident}
              defaultBidentChecked={defaultBidentChecked}
              isSetRelated={isSetRelated}
              gaugeLocation={gaugeLocation}
              isExternalUser={isExternalUser}
            />
          </Grid>
          <Grid item xs={6}>
            <OrderInformations loaderData={data} />
          </Grid>
          <Grid item xs={12} data-testid="shipment-section">
            <Shipment
              booking_id={data.booking_id}
              bidentPrice={bidentPrice}
              totalPrice={totalPrice}
              updateIsSetRelated={setIsSetRelated}
              bookingStatus={actualStatus}
              isExternalUser={isExternalUser}
            />
          </Grid>
        </Grid>
      </FormProvider>
    </ContainerOutlet>
  );
}
