import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { DeliveryMode } from '../../utils';
import { RootState } from './store';

/**
 * state model
 */
export interface GaugeState {
  bookingMode: DeliveryMode;
}

/**
 * set initial state
 */
const initialState: GaugeState = {
  bookingMode: DeliveryMode.Delivery,
};

/**
 * selectors for this slice
 */
export const selectBookingMode = (state: RootState) => state.gauge.bookingMode;

/**
 * gauge slice
 */
export const gaugeSlice = createSlice({
  name: 'gauge',
  initialState,
  reducers: {
    // set booking mode
    setBookingMode: (state, action: PayloadAction<DeliveryMode>) => {
      state.bookingMode = action.payload;
    },
  },
});

/**
 * actions for this slice
 */
export const { setBookingMode } = gaugeSlice.actions;
