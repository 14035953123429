import { TypeOf, array, boolean, number, object, string } from 'zod';

import { Currency, DispatchBy, REQUIRED_FIELD, ShippingSource } from '../../../utils';
import { getDhlAccountNumber } from '../../../utils/functions/shipping';

export const makeReturnGaugeFormSchema = object({
  designation_vam: string().min(1, REQUIRED_FIELD),
  drawing_number: string().min(1, REQUIRED_FIELD),
  id: number(),
  is_damaged: boolean(),
  is_gauge_required: boolean(),
  is_present: boolean(),
  reference_number: string().min(1, REQUIRED_FIELD),
  serial_number: string().optional().nullable(),
});

export const makeReturnSetFormSchema = object({
  application: string().min(1, REQUIRED_FIELD),
  business_number: string().min(1, REQUIRED_FIELD),
  case_weight: number(),
  connection: string().min(1, REQUIRED_FIELD),
  end: string().min(1, REQUIRED_FIELD),
  gauge_location: string().min(1, REQUIRED_FIELD),
  gauge_weight: number(),
  gauges: array(makeReturnGaugeFormSchema),
  max_weight: number(),
  min_weight: number(),
  od_inch: number(),
  option: string().min(1, REQUIRED_FIELD),
  proforma_value: string().min(1, REQUIRED_FIELD),
  set_id: number(),
  booking_id: number(),
  total_weight: number(),
  comments: string().optional(),
  grid_status: string().optional(),
});

export const makeReturnFormSchema = object({
  account_number: string().optional(),
  air_way_bill: string().min(1, REQUIRED_FIELD),
  comments: string().optional(),
  currency: string().min(1, REQUIRED_FIELD),
  dispatch_by: string().min(1, REQUIRED_FIELD),
  incoterms: string().min(1, REQUIRED_FIELD),
  nb_boxes: number(),
  sets: array(makeReturnSetFormSchema),
});

export const defaultValues: FormSchemaType = {
  account_number: getDhlAccountNumber(ShippingSource.Return),
  air_way_bill: '',
  comments: '',
  currency: Currency.EUR,
  dispatch_by: DispatchBy.DHL,
  incoterms: 'EXW',
  nb_boxes: 1,
  sets: [],
};

export type FormSchemaType = TypeOf<typeof makeReturnFormSchema>;
