import { TFunction } from 'i18next';

import { SetBookingInformations } from '../../interfaces/veg';
import { $darkGreen, $darkRed, $orange } from '../../styles/colors';
import { OD_MAPPING, SetState } from '../constants';
import { formatDate } from './date';
import { isNotNullOrUndefined } from './object';

interface SetIsState {
  status: SetState;
  background: string;
}

export interface SetBookingInformationsValues {
  title: string;
  name: string | number;
}

export function getSetState(
  isComplete: boolean,
  nextCalibrationDate: Date,
  t: TFunction<'gauge', undefined>
): SetIsState {
  const calibrated = nextCalibrationDate.getTime() > new Date().getTime();

  if (isComplete && calibrated) {
    return { status: t(`setCreation.setState.${SetState.Complete}`), background: $darkGreen };
  }

  if (isComplete && !calibrated) {
    return {
      status: t(`setCreation.setState.${SetState.CompleteNotCalibrated}`),
      background: $orange,
    };
  }

  if (!isComplete && calibrated) {
    return { status: t(`setCreation.setState.${SetState.Incomplete}`), background: $darkRed };
  }

  if (!isComplete && !calibrated) {
    return {
      status: t(`setCreation.setState.${SetState.IncompleteNotCalibrated}`),
      background: $darkRed,
    };
  }
}

export const getSetBookingInformationsValues = (
  setBookingInformations: SetBookingInformations,
  t: TFunction<'gauge', 'manageBookingDetail.shipment'>
): SetBookingInformationsValues[] => [
  {
    title: t('informationBoxes.suggested'),
    name: setBookingInformations?.set_id
      ? `#${setBookingInformations.set_id} ${setBookingInformations.connection} ${
          OD_MAPPING[setBookingInformations.od_inch]
        } ${setBookingInformations.min_weight}# to ${setBookingInformations.max_weight}# ${
          setBookingInformations.end
        } ${setBookingInformations.application}`
      : '-',
  },
  {
    title: t('informationBoxes.stock'),
    name: isNotNullOrUndefined(setBookingInformations?.stock) ? setBookingInformations?.stock : '-',
  },
  {
    title: t('informationBoxes.rate'),
    name: isNotNullOrUndefined(setBookingInformations?.usage_rate)
      ? `${setBookingInformations?.usage_rate}%`
      : '-',
  },
  {
    title: t('informationBoxes.nextCalibration'),
    name: isNotNullOrUndefined(setBookingInformations?.next_set_calibration_date)
      ? `${formatDate(setBookingInformations?.next_set_calibration_date, false)}`
      : '-',
  },
];
